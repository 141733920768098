<template>
  <div class="view">
    <nav>
      <router-link to="/">Code Generator</router-link>
    </nav>
    <div>
      <router-view/>
    </div>
  </div>
</template>

<style lang="scss" src="./styles.scss"></style>
<style lang="scss" scoped>
.view {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;

  > nav {
    display: none;
    text-align: center;
    padding: 1em;

    a {
      display: inline-block;
      font-weight: bold;

      padding: 0.5em 2em;
      text-decoration: none;
    }
  }

  > div {
    position: relative;
    flex: 1 1000 auto;
  }
}
</style>
