<template>
  <div class="view">
    <div class="output">
      <canvas ref="canvas"></canvas>
    </div>
    <div class="panel">
      <input v-model="code" :type="type === 'free' ? 'text' : 'number'" @blur="blur()" @focus="focus()"
             @input="render()">
      <div class="buttons">
        <button
            v-for="t of types"
            :class="{selected:type===t.value}"
            :disabled="t.text && formats.find(f => f.value === format)?.number"
            @click="setType(t.value)"
        >{{ t.label }}</button>
      </div>
      <div class="buttons">
        <button
            v-for="f of formats"
            :class="{selected:format===f.value}"
            :disabled="f.number && types.find(t => t.value === type)?.text"
            @click="setFormat(f.value)"
        >{{ f.label }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import Code from "bwip-js";

export default {
  data() {
    return {
      code: "",
      type: "article",
      format: "qrcode",
      types: [
        { value: "location", label: "Location", prefix: "31" },
        { value: "container", label: "Container", prefix: "32" },
        { value: "stock", label: "Stock", prefix: "33" },
        { value: "article", label: "Article", prefix: "03" },
        { value: "free", label: "Free", text: true },
      ],
      formats: [
        { value: "datamatrix", label: "DMC" },
        { value: "qrcode", label: "QR" },
        { value: "interleaved2of5", label: "ITF", number: true },
        { value: "code39", label: "Code39", number: true },
        { value: "code128", label: "Code128", number: true },
      ],
    };
  },
  methods: {
    setFormat( format ) {
      this.format = format;
      this.render();
    },
    setType( type ) {
      this.type = type;
      this.render();
    },
    render() {
      if ( this.code ) {
        let text;

        const prefix = this.types.find( t => t.value === this.type )?.prefix;

        if ( prefix && /^\d{1,8}$/.test( String( this.code ).replace( /\s/g, "" ) ) ) {
          text = prefix + ( "0000000" + parseInt( this.code, 10 ) ).slice( -8 );
        } else {
          text = String( this.code ).trim();
        }

        Code.toCanvas( this.$refs.canvas, {
          bcid: this.format,
          text,
          scale: 10,
        } );
      } else {
        const c = this.$refs.canvas;
        c.getContext( "2d" ).clearRect( 0, 0, c.width, c.height );
      }
    },
    focus() {
      this.scroller = () => {
        window.scrollTo( { top: 0, behavior: "auto" } );
      };

      window.addEventListener( "scroll", this.scroller );
    },
    blur() {
      window.removeEventListener( "scroll", this.scroller );
    }
  }
}
</script>

<style lang="scss" scoped>
input {
  font: normal 24px sans-serif;
}

.output {
  position: sticky;
  transform: translate3d(0, 0, 0);
  top: 1em;
  text-align: center;
}

canvas {
  width: 200px;
  height: 200px;
}

.panel {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin-top: 2em;
  position: relative;
  z-index: 2;

  > * {
    margin-bottom: 1em;
  }
}

.buttons {
  display: flex;
  flex-flow: row;
}

button {
  padding: 0.3em 0.7em;
  border: 1px solid #0006;
  border-radius: 0;
  font: inherit;

  &.selected {
    background: blue;
    color: white;
  }
}
</style>
